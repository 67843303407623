<template>
  <div class="py-md-8 py-6">
    <v-row>
      <v-col lg="8" md="12">
        <v-form v-model="formIsValid" ref="addressForm" @submit.prevent="handleFormSubmit" lazy-validation>
          <v-row>
            <v-col cols="12">
              <label class="text-subtitle-2 gray--text text--darken-2">
                {{ $t("createOrder.address.form.addressLine1.label") }}
              </label>
              <v-text-field
                class="mb-2 mt-2"
                v-model="form.addressLine1"
                :rules="rules.addressLine1"
                :placeholder="$t('createOrder.address.form.addressLine1.placeholder')"
                required
                outlined
                single-line
                hide-details="auto"
              />
              <v-text-field
                v-model="form.addressLine2"
                :placeholder="$t('createOrder.address.form.addressLine2.placeholder')"
                outlined
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-4" cols="12" md="4">
              <label class="text-subtitle-2 gray--text text--darken-2">
                {{ $t("createOrder.address.form.city.label") }}
              </label>
              <v-text-field
                class="mt-2"
                v-model="form.city"
                :rules="rules.city"
                outlined
                required
                single-line
                hide-details="auto"
              />
            </v-col>
            <v-col class="mt-4" cols="12" md="4">
              <label class="text-subtitle-2 gray--text text--darken-2">
                {{ $t("createOrder.address.form.state.label") }}
              </label>
              <v-autocomplete
                class="mt-2"
                :items="states"
                item-text="name"
                item-value="id"
                v-model="form.stateId"
                :rules="rules.stateId"
                outlined
                required
                hide-details="auto"
              />
            </v-col>
            <v-col class="mt-4" cols="12" md="4">
              <label class="text-subtitle-2 gray--text text--darken-2">
                {{ $t("createOrder.address.form.zipCode.label") }}
              </label>
              <v-text-field
                class="mt-2"
                v-model="form.zipCode"
                :rules="rules.zipCode"
                v-mask="'#####'"
                required
                outlined
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-4" cols="12">
              <label class="text-subtitle-2 gray--text text--darken-2">
                {{ $t("createOrder.address.form.phoneNumber.label") }}
              </label>
              <v-text-field
                class="mt-2"
                v-model="form.phoneNumber"
                :rules="rules.phoneNumber"
                v-mask="'###-###-####'"
                required
                outlined
                single-line
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <div class="mt-8 action-buttons">
            <v-btn
              type="button"
              elevation="0"
              class="mr-4 rounded-0"
              outlined
              :disabled="submitting"
              @click="goToPreviousStep"
              large
            >
              {{ $t("defaults.back") }}
            </v-btn>
            <v-btn
              type="submit"
              elevation="0"
              color="primary"
              :loading="submitting"
              :disabled="!formIsFilled || !formIsValid"
              class="rounded-0"
              large
            >
              {{ $t("createOrder.address.actions.makeADeposit") }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      formIsValid: false,
      submitting: false,
      states: [],
      form: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        stateId: null,
        zipCode: null,
        phoneNumber: null
      },
      rules: {
        addressLine1: [v => !!v || this.$t("createOrder.address.form.addressLine1.rules.required")],
        city: [v => !!v || this.$t("createOrder.address.form.city.rules.required")],
        stateId: [v => !!v || this.$t("createOrder.address.form.state.rules.required")],
        zipCode: [
          v => !!v || this.$t("createOrder.address.form.zipCode.rules.required"),
          v => /^[0-9]{5}$/.test(v) || this.$t("createOrder.address.form.zipCode.rules.format")
        ],
        phoneNumber: [
          v => !!v || this.$t("createOrder.address.form.phoneNumber.rules.required"),
          v => /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(v) || this.$t("createOrder.address.form.phoneNumber.rules.format")
        ]
      }
    };
  },
  mounted() {
    this.getAllStates();
    const { phoneNumber } = this.$store.state.Customer;
    if (phoneNumber) {
      this.form.phoneNumber = phoneNumber;
    }
  },
  computed: {
    ...mapState("KitchenLayout", {
      selectedKitchenLayoutType: state => state.selectedKitchenLayoutType,
      sqft: state => state.sqft
    }),
    ...mapState("Colors", {
      selectedCabinetColor: state => state.selectedCabinetColor,
      selectedFloorColor: state => state.selectedFloorColor
    }),
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone
    }),
    ...mapState("KitchenDetails", {
      hasOldCountertopRemoval: state => state.hasOldCountertopRemoval,
      selectedCutouts: state => state.selectedCutouts
    }),
    ...mapState("Customer", {
      customer: state => state
    }),
    formIsFilled() {
      return this.form.addressLine1 && this.form.city && this.form.stateId && this.form.zipCode && this.form.phoneNumber;
    }
  },
  methods: {
    ...mapActions("Steps", ["completeStep"]),
    async handleFormSubmit() {
      const isValid = this.$refs.addressForm.validate();
      if (isValid) {
        this.submitting = true;
        try {
          const { orderId, depositStripePaymentClientSecret } = await window.API.createOrder({
            kitchenLayoutTypeId: this.selectedKitchenLayoutType.id,
            totalCountertopSquareFootage: this.sqft,
            floorColorId: this.selectedFloorColor.id,
            cabinetColorId: this.selectedCabinetColor.id,
            stoneId: this.selectedStone.id,
            hasOldCountertopRemoval: this.hasOldCountertopRemoval,
            customer: this.customer,
            jobsiteAddress: this.form,
            cutouts: this.selectedCutouts.map(cutout => ({
              cutoutTypeId: cutout.id,
              quantity: cutout.quantity
            }))
          });

          localStorage.setItem(this.$constants.DEPOSIT_STRIPE_PAYMENT_CLIENT_SECRET_STORAGE_KEY, depositStripePaymentClientSecret);
          localStorage.setItem(this.$constants.ORDER_ID_STORAGE_KEY, orderId);

          this.completeStep("address");
          this.$router.push("/order-countertop/deposit");
        } finally {
          this.submitting = false;
        }
      }
    },
    goToPreviousStep() {
      this.$router.push("/order-countertop/kitchen-details");
    },
    async getAllStates() {
      this.states = await window.API.fetchAllStates();
    }
  }
};
</script>